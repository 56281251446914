import React, { useState } from "react";
import ProjectCard from "../../components/UI/projectCard";
import proj from "./projects.json";
import Modal from "../../components/Blocks/Modal.jsx";

const ProjectPage = () => {
  const projects = proj.projects;

  const [selectedProject, setSelectedProject] = useState(null);

  const handleCardClick = (project) => {
    setSelectedProject(project);
  };

  const handleCloseModal = () => {
    setSelectedProject(null);
  };

  return (
    <div className="">
      <div className="">
        <div className="relative w-full h-[50dvh] -mt-20 flex items-center justify-center">
          <div
            className="absolute inset-0 w-full h-full blur-[1.75px]"
            style={{
              backgroundImage: "url('./assets/images/Projects/ProjectsH.png')",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
          <div className="relative z-10 bg-primary/60 backdrop-blur-[2px] rounded shadow-lg shadow-black/50">
            <h1 className="rounded-xl px-8 py-4 text-2xl font-bold text-text md:text-3xl lg:text-5xl">
              Our Projects
            </h1>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-center pt-12 gap-4">
        {projects.map((project, index) => (
          <div
            key={index}
            className={`${
              index % 5 === 0
                ? "w-full sm:w-1/3 lg:w-2/4 "
                : "w-full sm:w-1/3 lg:w-2/5"
            } px-2 pb-6 flex-grow h-[450px]`}
          >
            <ProjectCard
              name={project.name}
              image={project.image}
              onClick={() => handleCardClick(project)}
            />
          </div>
        ))}
        {selectedProject && (
          <Modal project={selectedProject} onClose={handleCloseModal} />
        )}
      </div>
    </div>
  );
};

export default ProjectPage;
