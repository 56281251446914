import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import Home from "./pages/HomePage/homePage";
import ContactPage from "./pages/ContactPage/contactPage";
import AboutPage from "./pages/AboutPage/aboutPage";
import ServicesPage from "./pages/ServicesPage/servicesPage";
import ProjectPage from "./pages/ProjectsPage/projectPage";
import NewsroomPage from "./pages/NewsroomPage/newsroomPage";
import CareersPage from "./pages/CareersPage/careersPage";

function App() {
  useEffect(() => {
    AOS.init({ duration: 1000, mirror: true });
  }, []);

  return (
    <div className=" bg-background">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="*" element={<Home />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/projects" element={<ProjectPage />} />
        <Route path="/newsroom" element={<NewsroomPage />} />
        <Route path="/careers" element={<CareersPage />} />
      </Routes>
    </div>
  );
}

export default App;
