import React from "react";
import { useTranslation } from "react-i18next";

function LanguageDropdown() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = React.useState("en");

  const languages = [
    { code: "en", name: t("NBLangEn"), title: "En" },
    { code: "ar", name: t("NBLangAr"), title: "Ar" },
  ];

  const changeLanguage = (languageCode) => {
    i18n.changeLanguage(languageCode);
    localStorage.setItem("language", languageCode); // Save language preference
    setCurrentLanguage(languageCode);
  };

  return (
    <div className="flex space-x-2 w-4 md:space-x-6">
      {languages.map((language) =>
        currentLanguage === language.code ? (
          <button
            key={language.code}
            className="hidden"
            onClick={() => changeLanguage(language.code)}
          >
            {language.title}
          </button>
        ) : (
          <button
            key={language.code}
            className="text-gray-500"
            onClick={() => changeLanguage(language.code)}
          >
            {language.title}
          </button>
        )
      )}
    </div>
  );
}

export default LanguageDropdown;
