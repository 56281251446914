import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHammer,
  faBuilding,
  faDraftingCompass,
  faTools,
  faHardHat,
  faRulerCombined,
} from "@fortawesome/free-solid-svg-icons";

const services = [
  {
    id: 1,
    icon: faHammer,
    title: "Construction Management",
    description:
      "We provide comprehensive construction management services, overseeing every phase of your project from planning to completion. Our team ensures timely and budget-friendly execution.",
    delay: 0,
  },
  {
    id: 2,
    icon: faBuilding,
    title: "Design-Build",
    description:
      "Our design-build services offer a streamlined process where design and construction teams work together, enhancing communication and reducing costs and timelines.",
    delay: 100,
  },
  {
    id: 3,
    icon: faDraftingCompass,
    title: "Architectural Design",
    description:
      "Our experienced architects create innovative and functional designs tailored to meet your specific needs and aesthetic preferences.",
    delay: 200,
  },
  {
    id: 4,
    icon: faTools,
    title: "Renovation & Remodeling",
    description:
      "From minor updates to complete overhauls, we specialize in transforming spaces to enhance functionality and visual appeal while adhering to your budget.",
    delay: 300,
  },
  {
    id: 5,
    icon: faHardHat,
    title: "Project Planning",
    description:
      "We offer meticulous project planning services, ensuring every detail is accounted for, from initial concept to final execution, to deliver outstanding results.",
    delay: 400,
  },
  {
    id: 6,
    icon: faRulerCombined,
    title: "Interior Design",
    description:
      "Our interior design services focus on creating harmonious and functional spaces that reflect your style and enhance your living or working environment.",
    delay: 500,
  },
];

const DetailedServices = () => {
  return (
    <section className="py-16 bg-background text-text">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-extrabold text-center mb-12">
          Our Services
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4">
          {services.map((service) => (
            <div
              data-aos="fade-up"
              data-aos-delay={service.delay}
              key={service.id}
              className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center text-center"
            >
              <FontAwesomeIcon
                icon={service.icon}
                className="text-4xl text-text mb-4"
              />
              <h3 className="text-xl font-semibold">{service.title}</h3>
              <p className="text-gray-700 mt-2">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DetailedServices;
