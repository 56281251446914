import React from "react";
import MapComponent from "../../components/Blocks/MapComponent";

const BranchMap = () => {
  return (
    <div className="w-full h-full">
      <MapComponent />
    </div>
  );
};

export default BranchMap;
