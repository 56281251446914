import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHammer,
  faBuilding,
  faDraftingCompass,
  faTools,
  faHardHat,
  faRulerCombined,
} from "@fortawesome/free-solid-svg-icons";

const services = [
  {
    icon: faHammer,
    title: "Construction Management",
    description:
      "We provide comprehensive construction management services, overseeing every phase of your project from planning to completion. Our team ensures timely and budget-friendly execution.",
    delay: 0,
  },
  {
    icon: faBuilding,
    title: "Design-Build",
    description:
      "Our design-build services offer a streamlined process where design and construction teams work together, enhancing communication and reducing costs and timelines.",
    delay: 100,
  },
  {
    icon: faDraftingCompass,
    title: "Architectural Design",
    description:
      "Our experienced architects create innovative and functional designs tailored to meet your specific needs and aesthetic preferences.",
    delay: 200,
  },
  {
    icon: faTools,
    title: "Renovation & Remodeling",
    description:
      "From minor updates to complete overhauls, we specialize in transforming spaces to enhance functionality and visual appeal while adhering to your budget.",
    delay: 300,
  },
  {
    icon: faHardHat,
    title: "Project Planning",
    description:
      "We offer meticulous project planning services, ensuring every detail is accounted for, from initial concept to final execution, to deliver outstanding results.",
    delay: 400,
  },
  {
    icon: faRulerCombined,
    title: "Interior Design",
    description:
      "Our interior design services focus on creating harmonious and functional spaces that reflect your style and enhance your living or working environment.",
    delay: 500,
  },
];

function Services() {
  return (
    <section className="py-12 bg-white text-center">
      <h2 className="text-3xl font-bold mb-16" data-aos="fade-up">
        Our Services
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4">
        {services.map((service, index) => (
          <div key={index} data-aos="fade-up" data-aos-delay={service.delay}>
            <FontAwesomeIcon
              icon={service.icon}
              className="text-4xl text-text"
            />
            <h3 className="mt-4 text-xl font-semibold">{service.title}</h3>
            <p className="text-gray-700 mt-2">{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Services;
