import React from "react";

function Team() {
  return (
    <section className="py-12 bg-white text-center text-text">
      <h2 className="text-3xl font-bold mb-6">Meet Our Team</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {[
          {
            src: "./assets/images/About/MemberMale.png",
            name: "John Doe",
            role: "CEO",
            delay: 0,
          },
          {
            src: "./assets/images/About/MemberFemale.png",
            name: "Jane Smith",
            role: "Project Manager",
            delay: 100,
          },
          {
            src: "./assets/images/About/MemberMale.png",
            name: "John Doe",
            role: "CEO",
            delay: 200,
          },
          {
            src: "./assets/images/About/MemberFemale.png",
            name: "Jane Smith",
            role: "Project Manager",
            delay: 300,
          },
          {
            src: "./assets/images/About/MemberMale.png",
            name: "John Doe",
            role: "CEO",
            delay: 400,
          },
          {
            src: "./assets/images/About/MemberFemale.png",
            name: "Jane Smith",
            role: "Project Manager",
            delay: 500,
          },
        ].map((member, index) => (
          <div key={index} data-aos="fade-up" data-aos-delay={member.delay}>
            <img
              src={member.src}
              alt="Team Member"
              className="w-48 h-48 rounded-full mx-auto  hover:shadow-xl hover:shadow-black/30 cursor-pointer"
            />
            <h3 className="mt-4 text-xl font-semibold">{member.name}</h3>
            <p className="text-gray-700">{member.role}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Team;
