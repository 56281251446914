import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import L from "leaflet";

const blackIcon = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
const branches = [
  {
    id: 1,
    name: "Company's main branch",
    description: "The Company's main branch at Giza",
    lat: 30.05451557699789,
    lng: 31.20507647282846,
  },
];

const MapComponent = () => {
  const branchLocation = [30.05451557699789, 31.20507647282846]; // Example coordinates for Giza
  return (
    <MapContainer
      center={branchLocation}
      zoom={15}
      style={{ height: "100%", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {branches.map((branch) => (
        <Marker key={branch.id} position={[branch.lat, branch.lng]} icon={blackIcon}>
          <Popup>
            <b>{branch.name}</b>
            <br />
            {branch.description}
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default MapComponent;
