import React from "react";
function ProjectCard({ name, image, onClick }) {
  return (
    <div
      className="  flex flex-col  bg-cover drop-shadow-lg justify-center h-[350px] min-h-fit rounded-md"
      style={{ backgroundImage: `url(${image})` }}
      onClick={onClick}
    >
      <div className="flex flex-col text-white bg-nav bg-opacity-75 justify-center cursor-pointer rounded-md hover:bg-opacity-0 w-full h-full hover:text-transparent transition-all ease-in-out duration-300">
        <h3 className="   text-center text-wrap text-2xl font-semibold mx-6 ">
          {name}
        </h3>
      </div>
    </div>
  );
}

export default ProjectCard;
