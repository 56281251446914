import React from "react";

const achievements = [
  {
    title: "100+ Completed Projects",
    description:
      "Our portfolio boasts over 100 completed projects, ranging from residential homes to large commercial buildings.",
    delay: 0,
  },
  {
    title: "Best Construction Company Award 2023",
    description:
      "We were honored with the Best Construction Company Award in 2023 for our exceptional performance and client satisfaction.",
    delay: 100,
  },
  {
    title: "ISO 9001 Certification",
    description:
      "Our commitment to quality is demonstrated by our ISO 9001 certification, ensuring our services meet international standards.",
    delay: 200,
  },
  {
    title: "Innovative Green Building Solutions",
    description:
      "We lead the industry in sustainable building practices, incorporating green solutions into our projects to reduce environmental impact.",
    delay: 300,
  },
  {
    title: "Client Satisfaction Rate",
    description:
      "We pride ourselves on a high client satisfaction rate, consistently delivering projects that exceed expectations.",
    delay: 400,
  },
  {
    title: "Community Engagement",
    description:
      "Our community engagement initiatives include supporting local charities and participating in neighborhood revitalization projects.",
    delay: 500,
  },
];
function Achievments() {
  return (
    <section className="py-20 pb-32 bg-white text-center text-text">
      <h2 className="text-3xl font-bold mb-6" data-aos="fade-up">
        Our Achievements
      </h2>
      <p
        className="max-w-3xl mx-auto text-lg"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        Over the years, Verdi has achieved numerous milestones, reflecting our
        dedication to excellence and innovation in the construction industry.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-6 px-4">
        {achievements.map((achievement, index) => (
          <div
            data-aos="fade-up"
            data-aos-delay={achievement.delay}
            key={index}
          >
            <div
              key={index}
              className="bg-primary p-4 rounded-lg shadow-xtransition-all ease-in-out duration-300"
            >
              <h3 className="text-xl font-semibold">{achievement.title}</h3>
              <p className="mt-2 text-gray-700">{achievement.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Achievments;
