import React from "react";

function Touch() {
  return (
    <section className="py-12 bg-white text-center flex flex-col items-center text-text">
      <h2 className="text-3xl font-bold mb-6">Get In Touch</h2>
      <p className="max-w-3xl mx-auto text-lg">
        Have questions or want to discuss your project? Contact us today!
      </p>
      <div className="mt-6 text-xl text-start flex flex-col justify-center">
        <p>Email: info@verdi.com</p>
        <p>Phone: +20 122 213 1600</p>
        <p>Address : 41 El Batel Amed Abd El-Aziz, Mohandsin, Giza</p>
      </div>
    </section>
  );
}

export default Touch;
