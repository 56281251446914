import React, { useState } from "react";
import FacebookPostModal from "../../components/Blocks/FacebookPostModal";

const newsItems = [
  {
    id: 1,
    title: "Company Expands to New Markets",
    date: "August 1, 2024",
    content:
      "We are excited to announce our expansion into new markets, providing even more value to our customers.",
    image: "/assets/images/Newsroom/News1.jpg",
    span: "sm:row-span-2 sm:col-span-2",
  },
  {
    id: 2,
    title: "New Product Launch",
    date: "July 15, 2024",
    content:
      "Our latest product is now available. Learn more about its features and benefits.",
    image: "/assets/images/Newsroom/News2.jpg",
    span: "sm:col-span-1 sm:row-span-1",
  },
  {
    id: 3,
    title: "Annual Report Released",
    date: "June 30, 2024",
    content:
      "Our annual report has been released, highlighting our achievements and growth over the past year.",
    image: "/assets/images/Newsroom/News3.jpg",
    span: "sm:col-span-1 sm:row-span-1",
  },
  {
    id: 4,
    title: "CEO Interview on Industry Trends",
    date: "May 20, 2024",
    content:
      "Our CEO shares insights on the latest industry trends in a recent interview.",
    image: "/assets/images/Newsroom/News4.jpg",
    span: "sm:row-span-2 sm:col-span-1",
  },
  {
    id: 5,
    title: "Revolutionizing Sustainability",
    date: "April 10, 2024",
    content:
      "Learn about our new sustainability initiatives and how we are reducing our environmental impact.",
    image: "/assets/images/Newsroom/News5.jpg",
    span: "sm:col-span-1 sm:row-span-1",
  },
  {
    id: 6,
    title: "Sustainability Initiatives",
    date: "April 10, 2024",
    content:
      "Learn about our new sustainability initiatives and how we are reducing our environmental impact.",
    image: "/assets/images/Newsroom/News6.jpg",
    span: "sm:col-span-1 sm:row-span-1",
  },
  {
    id: 7,
    title: "Partnership Announcement",
    date: "March 5, 2024",
    content:
      "We are pleased to announce a new partnership that will enhance our service offerings.",
    image: "/assets/images/Newsroom/News7.jpg",
    span: "sm:col-span-2 sm:row-span-1",
  },
];

const NewsroomPage = () => {
  const [selectedPost, setSelectedPost] = useState(null);

  const handleCardClick = (post) => {
    setSelectedPost(post);
  };

  const handleCloseModal = () => {
    setSelectedPost(null);
  };

  return (
    <div className="container mx-auto p-6 pb-28">
      <h1 className="text-4xl font-bold text-center mb-8">Newsroom</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {newsItems.map((item) => (
          <div
            key={item.id}
            className={`hover:cursor-pointer hover:shadow-black/70 duration-300 hover:scale-105 h-fit sm:h-full md:max-h-fit min-w-lg bg-white shadow-lg flex flex-col justify-between rounded-lg overflow-hidden col-span-2 ${item.span}`}
            onClick={() => handleCardClick(item)}
          >
            <img
              src={item.image}
              alt={item.title}
              className="w-full h-full object-cover hover:opacity-60 duration-300"
            />
            <div className="p-6">
              <h2 className="text-2xl font-bold mb-2">{item.title}</h2>
              <p className="text-gray-600 mb-4">{item.date}</p>
              <p className="text-gray-800">{item.content}</p>
            </div>
          </div>
        ))}
      </div>
      {selectedPost && (
        <FacebookPostModal post={selectedPost} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default NewsroomPage;
