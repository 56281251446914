import React from "react";
const testimonials = [
  {
    quote:
      "Verdi delivered our project on time and within budget. Their team was professional and attentive to our needs, ensuring every detail was perfect.",
    name: "John Smith, CEO of XYZ Corp",
    delay: 0,
  },
  {
    quote:
      "The quality of work and level of service we received from Verdi was outstanding. They truly went above and beyond to meet our expectations.",
    name: "Jane Doe, Homeowner",
    delay: 100,
  },
  {
    quote:
      "Verdi's innovative approach and attention to detail resulted in a beautiful, functional space that we are proud to call our office.",
    name: "Michael Brown, Director of Operations at ABC Inc",
    delay: 200,
  },
  {
    quote:
      "From start to finish, the Verdi team was dedicated, professional, and efficient. We couldn't be happier with the final product.",
    name: "Sarah Johnson, Property Developer",
    delay: 300,
  },
];

function Testimonials() {
  return (
    <section className="py-12 bg-background text-center">
      <h2 className="text-3xl font-bold mb-6" data-aos="fade-up">
        What Our Clients Say
      </h2>
      <div className="max-w-3xl mx-auto">
        {testimonials.map((testimonial, index) => (
          <div
            data-aos="fade-up"
            data-aos-delay={testimonial.delay}
            key={index}
          >
            <div className="bg-white p-6 rounded-lg shadow-md mb-6  cursor-pointer transition-all ease-in-out duration-300">
              <p className="text-lg italic">{testimonial.quote}</p>
              <p className="mt-4 font-semibold">{testimonial.name}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Testimonials;
