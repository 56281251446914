import React, { useState } from "react";

const CareersPage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    nationalID: "",
    resume: null,
    jobRole: "",
    coverLetter: "",
    Linkedin: "",
  });

  const jobRoles = ["Developer", "Designer", "Project Manager", "Analyst"]; // Example roles

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "resume") {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData();
    for (const key in formData) {
      form.append(key, formData[key]);
    }

    const res = await fetch("/api/apply", {
      method: "POST",
      body: form,
    });

    if (res.ok) {
      alert("Application submitted successfully!" + formData);
      // Reset form or handle success
    } else {
      alert("There was an error submitting your application.");
    }
  };

  return (
    <div className="container mx-auto px-4 pb-28 pt-8">
      <h1 className="text-3xl font-bold text-center mb-8">Careers</h1>
      <form
        onSubmit={handleSubmit}
        className="space-y-4 max-w-lg mx-auto bg-white p-6 shadow-xl shadow-black/70 drop-shadow-2xl"
      >
        <div className="flex gap-x-6">
          <div className=" w-1/2">
            <label className="block text-gray-700 font-semibold mb-2">
              First Name
            </label>
            <input
              className="w-full p-2 border border-gray-300 rounded"
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className=" w-1/2">
            <label className="block text-gray-700 font-semibold mb-2">
              Last Name
            </label>
            <input
              className="w-full p-2 border border-gray-300 rounded"
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="mb-4 ">
          <label className="block text-gray-700 font-semibold mb-2">
            Email
          </label>
          <input
            className="w-full p-2 border border-gray-300 rounded"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4 ">
          <label className="block text-gray-700 font-semibold mb-2">
            Phone Number
          </label>
          <input
            className="w-full p-2 border border-gray-300 rounded"
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4 ">
          <label className="block text-gray-700 font-semibold mb-2">
            National ID
          </label>
          <input
            className="w-full p-2 border border-gray-300 rounded"
            type="number"
            name="nationalID"
            value={formData.nationalID}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4 ">
          <label className="block text-gray-700 font-semibold mb-2">
            Cover Letter
          </label>
          <textarea
            className="w-full p-2 border border-gray-300 rounded"
            rows={4}
            name="coverLetter"
            value={formData.coverLetter}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4 ">
          <label className="block text-gray-700 font-semibold mb-2">
            Linkedin Profile (Optional)
          </label>
          <input
            className="w-full p-2 border border-gray-300 rounded"
            type="text"
            name="Linkedin"
            value={formData.Linkedin}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4 ">
          <label className="block text-gray-700 font-semibold mb-2">
            Job Role
          </label>
          <select
            className="w-full p-2 border border-gray-300 rounded"
            name="jobRole"
            value={formData.jobRole}
            onChange={handleChange}
            required
          >
            <option value="">Select a job role</option>
            {jobRoles.map((role, index) => (
              <option key={index} value={role}>
                {role}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4 ">
          <label className="block text-gray-700 font-semibold mb-2">
            Upload Resume
          </label>
          <input
            className="w-full p-2 border border-gray-300 rounded"
            type="file"
            name="resume"
            onChange={handleChange}
            required
          />
        </div>
        <button
          type="submit"
          className="w-full  text-white py-2 rounded bg-nav hover:bg-nav/80 duration-300"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default CareersPage;
