import { TextGenerateEffect } from "../../components/Blocks/text-generate-effect.tsx";
import { useTranslation } from "react-i18next";

export default function TextGenerateEffectDemo() {
  const { t } = useTranslation();
  const words = t("homeH1P1");
  const comp = t("homeH1Comp");
  const main = t("homeH1");
  const btn = t("homeH1Btn");
  return (
    <TextGenerateEffect
      duration={2}
      filter={false}
      main={main}
      comp={comp}
      btn={btn}
      words={words}
    />
  );
}
