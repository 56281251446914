import React from "react";

function About() {
  return (
    <section id="about" className="py-16 bg-background">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">About Us</h2>
        <p className="text-center max-w-2xl mx-auto">
          Established in early 2004, Verdi General Contracting redefines the
          process of requirements elicitation, analysis, design, budgeting and
          implementation of Real-Estate, Civil developmental, and Facility
          Management projects. Since its inception Verdi General Contracting has
          been engaged tonumber of high profile projects, including projects
          that set the underlying foundation for a better quality of living for
          citizens and Facility Sustainability. Through its years of operation
          Verdi General Contracting has proved to be a dependable partner,
          delivering requirements on-time and within budget.
        </p>
      </div>
    </section>
  );
}

export default About;
