import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import "./i18n"; // import the i18n configuration
import { I18nextProvider } from "react-i18next";
import { NextUIProvider } from "@nextui-org/react";
import Nav from "./components/UI/Navbar";
import Footer from "./components/UI/Footer";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <NextUIProvider>
      <I18nextProvider>
        <Nav />
        <App />
        <Footer />
      </I18nextProvider>
    </NextUIProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
