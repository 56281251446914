import React from "react";

import Services from "./services";
import Testimonials from "./testimonials";
import Touch from "./touch";
import Team from "./team";

function AboutPage() {
  return (
    <div>
      <div className="relative w-full h-[50dvh] -mt-20 flex items-center justify-center">
        <div
          className="absolute inset-0 w-full h-full blur-[1.75px]"
          style={{
            backgroundImage: "url('./assets/images/About/AboutH.png')",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div className="relative z-10 bg-primary/60 backdrop-blur-[2px] rounded shadow-lg shadow-black/50">
          <h1 className="rounded-xl px-8 py-4 text-2xl font-bold text-text md:text-3xl lg:text-5xl">
            About Us
          </h1>
        </div>
      </div>
      <div>
        <section className="bg-background py-12 flex flex-row-reverse justify-between px-8 items-center">
          <img
            src="./assets/images/About/AboutH1.png"
            alt="a construction site"
            data-aos="fade-left"
            data-aos-mirror="true"
            className="w-5/12 hidden md:inline"
          />
          <div
            className="text-start md:w-5/12"
            data-aos="fade-right"
            data-aos-mirror="true"
            data-aos-delay="400"
          >
            <h2 className="text-3xl font-bold mb-6">Who We Are</h2>
            <p className="max-w-3xl mx-auto text-lg">
              Established in early 2004, Verdi General Contracting redefines the
              process of requirements elicitation, analysis, design, budgeting
              and implementation of Real-Estate, Civil developmental, and
              Facility Management projects. Since its inception Verdi General
              Contracting has been engaged tonumber of high profile projects,
              including projects that set the underlying foundation for a better
              quality of living for citizens and Facility Sustainability.
              Through its years of operation Verdi General Contracting has
              proved to be a dependable partner, delivering requirements on-time
              and within budget.
            </p>
          </div>
        </section>
        <section className="bg-background py-20 flex flex-row-reverse justify-between px-8 items-center">
          <div
            className="text-start md:w-5/12"
            data-aos="fade-right"
            data-aos-mirror="true"
            data-aos-delay="400"
          >
            <h2 className="text-3xl font-bold mb-6">Our Vision</h2>
            <p className="max-w-3xl mx-auto text-lg">
              To be One of the top construction and facility management
              organizations engaged in well reputed High Quality projects
              encompassing full spectrum management, in the Middle East within
              the next ten years.
            </p>
          </div>
          <img
            src="./assets/images/About/AboutH1.png"
            alt="a construction site"
            data-aos="fade-left"
            data-aos-mirror="true"
            className="w-5/12 hidden md:inline"
          />
        </section>
        <Team />
        <Services />
        <Testimonials />
        <Touch />
      </div>
    </div>
  );
}

export default AboutPage;
