import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faProjectDiagram } from "@fortawesome/free-solid-svg-icons";

function Services() {
  return (
    <section id="services" className="py-16 bg-background text-text">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="text-center">
            <FontAwesomeIcon
              icon={faBuilding}
              size="3x"
              className="text-text/80"
            />
            <h3 className="text-xl font-semibold mt-4">Construction</h3>
            <p className="mt-2">
              Top-notch construction services for commercial and residential
              projects.
            </p>
          </div>
          <div className="text-center">
            <FontAwesomeIcon
              icon={faProjectDiagram}
              size="3x"
              className="text-text/80"
            />
            <h3 className="text-xl font-semibold mt-4">Project Management</h3>
            <p className="mt-2">
              Expert project management from planning to execution.
            </p>
          </div>
          <div className="text-center">
            <FontAwesomeIcon
              icon={faBuilding}
              size="3x"
              className="text-text/80"
            />
            <h3 className="text-xl font-semibold mt-4">Renovation</h3>
            <p className="mt-2">
              Quality renovation services to give your space a fresh look.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
