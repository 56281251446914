import React from "react";
import ImageSlider from "../../components/Blocks/ImageSlider.tsx";
import projec from "../ProjectsPage/projects.json";
function Projects() {


  const projects = projec.projects;
  return (
    <section id="projects" className="py-16 bg-white text-text">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">Our Projects</h2>
        {/* {projects.map((project) => (
            <div
              key={project.id}
              className="bg-gradient-to-r from-lime-200 to-lime-300 p-4 rounded-lg shadow-xl shadow-gray-400 drop-shadow-lg"
            >
              <img
                src={project.image}
                alt={project.name}
                className="w-full h-48 object-cover rounded-lg"
              />
              <h3 className="text-xl font-semibold mt-4">{project.name}</h3>
              <p className="mt-2">{project.description}</p>
            </div>
          ))} */}
        <ImageSlider projects={projects} />
      </div>
    </section>
  );
}

export default Projects;
