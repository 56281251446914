import { useState } from "react";
import BranchMap from "../../components/UI/BranchMap";
export default function ContactPage() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");

    const theFormData = JSON.stringify(formData);
    console.log(theFormData);

    try {
      const res = await fetch("/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: theFormData,
      });

      const data = await res.json(); // Parse JSON response

      if (data.success) {
        setSuccessMessage("Your message has been sent successfully!");
        setFormData({ name: "", phone: "", email: "", message: "" });
      } else {
        setErrorMessage(
          "Failed to send your message. Please try again." + data.error
        );
      }
    } catch (error) {
      console.error("Error sending form data:", error);
      setErrorMessage("An error occurred. Please try again later.");
    }
  };
  return (
    <div className="">
      <div className="relative w-full h-[50dvh] -mt-20 flex items-center justify-center">
        <div
          className="absolute inset-0 w-full h-full blur-[1.75px]"
          style={{
            backgroundImage: "url('./assets/images/Contact/ContactH.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div className="relative z-10 bg-primary/60 backdrop-blur-[2px] rounded shadow-lg shadow-black/50">
          <h1 className="rounded-xl px-8 py-4 text-2xl font-bold text-text md:text-3xl lg:text-5xl">
            Contact Us{" "}
          </h1>
        </div>
      </div>
      <div className=" sticky flex w-full flex-col items-center justify-center rounded-lg bg-background py-4 font-bold text-text">
        <h2 className="mb-10 w-full py-6 text-center text-2xl md:text-3xl lg:text-4xl">
          Send Us a Message
        </h2>
        <div className="hidden w-10/12  rounded-lg bg-nav/70 px-4 py-1  md:flex">
          <div className="w-5/12">
            <div
              className="sticky -mt-8 h-full w-full rounded-lg blur-[0.5px] bg-nav shadow-xl shadow-nav/90 drop-shadow-xl "
              style={{
                backgroundImage: "url('/assets/images/Contact/Form.png')",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            />
          </div>
          <div className="w-7/12  py-10">
            <form onSubmit={handleSubmit} className="mx-8 w-3/4 space-y-4">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-semibold text-white "
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  name="name"
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm  sm:text-sm  focus:outline-accent"
                  placeholder="Your Name"
                />
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-semibold text-white"
                >
                  Phone Number
                </label>
                <input
                  type="number"
                  value={formData.phone}
                  onChange={handleChange}
                  id="phone"
                  name="phone"
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm  sm:text-sm focus:outline-accent"
                  placeholder="Your Phone Number"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm  font-semibold text-white"
                >
                  Email
                </label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  id="email"
                  name="email"
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm  sm:text-sm focus:outline-accent"
                  placeholder="Your Email"
                />
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="block text-sm  font-semibold text-white"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  value={formData.message}
                  onChange={handleChange}
                  name="message"
                  rows={4}
                  className="mt-1 focus:outline-accent block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm  sm:text-sm"
                  placeholder="Your Message"
                ></textarea>
              </div>
              <button
                type="submit"
                className="flex bg-nav text-white w-full justify-center rounded-md border border-transparent bg-primary-light px-4 py-2 text-sm font-medium  shadow-lg shadow-black/50 hover:scale-105 transition-all ease-in-out duration-200  "
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
        {successMessage && <p className="text-green-500">{successMessage}</p>}
        {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        <div className="relative flex w-10/12 rounded-lg  ">
          <div
            className="w-full  py-10 md:hidden rounded-lg shadow-xl shadow-nav/90 drop-shadow-xl "
            style={{
              backgroundImage: "url('/assets/images/Contact/Form.png')",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <form
              onSubmit={handleSubmit}
              className="mx-8 w-5/6 space-y-4 rounded-xl  bg-primary/70 px-8 py-4"
            >
              <div>
                <label
                  htmlFor="name"
                  className="block text-base font-medium text-gray-700 dark:text-gray-200"
                >
                  Name
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  id="name"
                  name="name"
                  className="mt-1 block w-full rounded-md border focus:outline-accent border-gray-300 px-3 py-2 shadow-sm  sm:text-sm"
                  placeholder="Your Name"
                />
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-base font-medium text-gray-700 dark:text-gray-200"
                >
                  Phone Number
                </label>
                <input
                  value={formData.phone}
                  onChange={handleChange}
                  type="number"
                  id="phone"
                  name="phone"
                  className="mt-1 block w-full rounded-md border focus:outline-accent border-gray-300 px-3 py-2 shadow-sm  sm:text-sm"
                  placeholder="Your Phone Number"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-base font-medium text-gray-700 dark:text-gray-200"
                >
                  Email
                </label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  id="email"
                  name="email"
                  className="mt-1 block w-full rounded-md border focus:outline-accent border-gray-300 px-3 py-2 shadow-sm  sm:text-sm"
                  placeholder="Your Email"
                />
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="block text-base font-medium text-gray-700 dark:text-gray-200"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  value={formData.message}
                  onChange={handleChange}
                  name="message"
                  rows={4}
                  className="mt-1 block w-full rounded-md border focus:outline-accent border-gray-300 px-3 py-2 shadow-sm  sm:text-sm"
                  placeholder="Your Message"
                ></textarea>
              </div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md border border-transparent bg-nav px-4 py-2 text-sm font-medium text-white shadow-lg shadow-black/40 "
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col bg-primary pb-14 mt-20 text-text">
        <h2 className="w-full py-6 text-center text-2xl md:text-3xl lg:text-4xl font-semibold">
          Contact Information
        </h2>
        <div className="flex gap-y-10 flex-col md:flex-row justify-center items-center">
          <div className="flex flex-col items-start justify-center  text-start font-bold w-full md:w-1/2 text-xl">
            <div className="mx-8 flex flex-col items-start justify-center">
              <h3>Head Office:</h3>
              <p className="text-start">
                <span className="font-bold">Address: </span>41 El Batel Amed Abd
                El-Aziz, Mohandsin, Giza
              </p>

              <br />
              <p className="text-start">
                <span className="font-bold">Phone: </span>+20 122 213 1600
              </p>
              <p className="text-start">
                <span className="font-bold">Email: </span>VerdiEG@gmail.com
              </p>
            </div>
          </div>
          <div className="mx-8 h-[40dvh] w-[80dvw] sm:h-[400px] md:h-[300px] sm:w-[400px] md:w-1/2">
            <BranchMap />
          </div>
        </div>
      </div>
    </div>
  );
}
