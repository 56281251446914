import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProjectCard from "../UI/projectCard";
import Modal from "./Modal";

const ImageSlider = ({ projects }) => {
  const [selectedProject, setSelectedProject] = useState(null);

  const handleCardClick = (project) => {
    setSelectedProject(project);
  };

  const handleCloseModal = () => {
    setSelectedProject(null);
  };
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 3, // Number of images shown at once
    slidesToScroll: 1,
    arrows: true,
    centerMode: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full px-4 overflow-visible">
      <Slider {...settings}>
        {projects.map((project, index) => (
          <div key={index} className=" px-2 pb-6 h-[450px] ">
            <ProjectCard
              name={project.name}
              image={project.image}
              onClick={() => {
                handleCardClick(project);
              }}
            />
          </div>
        ))}
      </Slider>

      {selectedProject && (
        <Modal project={selectedProject} onClose={()=>{handleCloseModal()}} />
      )}
    </div>
  );
};

export default ImageSlider;
