import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faUser,
  faCalendarAlt,
  faDollarSign,
  faCheckCircle,
  faScroll,
} from "@fortawesome/free-solid-svg-icons";

function Modal({ project, onClose }) {
  const handleClickOutside = (e) => {
    if (e.target.className.includes("fixed inset-0")) {
      onClose();
    }
  };
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-70 backdrop-blur-[2px] flex items-center justify-center z-50"
      onClick={handleClickOutside}
    >
      <div
        className="bg-white rounded-lg p-8 relative max-w-xl w-10/12"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="absolute top-0 right-2 text-black text-4xl"
          onClick={onClose}
        >
          &times;
        </button>
        <img
          src={project.image}
          alt={project.name}
          className="w-full h-48 object-cover rounded-md mb-4"
        />
        <h3 className="text-2xl font-bold mb-4">{project.name}</h3>
        <div className="mb-2">
          <FontAwesomeIcon icon={faBuilding} className="text-accent mr-2" />
          <span className="text-text">
            <strong>Client:</strong> {project.client}
          </span>
        </div>
        <div className="mb-2">
          <FontAwesomeIcon icon={faUser} className="text-accent mr-2" />
          <span className="text-text">
            <strong>Details:</strong> {project.details}
          </span>
        </div>
        <div className="mb-2">
          <FontAwesomeIcon icon={faCalendarAlt} className="text-accent mr-2" />
          <span className="text-text">
            <strong>Time:</strong> {project.time}
          </span>
        </div>
        <div className="mb-2">
          <FontAwesomeIcon icon={faDollarSign} className="text-accent mr-2" />
          <span className="text-text">
            <strong>Contract Sum:</strong> {project.contractSum}
          </span>
        </div>
        <div className="mb-2">
          <FontAwesomeIcon icon={faCheckCircle} className="text-accent mr-2" />
          <span className="text-text">
            <strong>Status:</strong> {project.status}
          </span>
        </div>
        <div className="mb-2">
          <FontAwesomeIcon icon={faScroll} className="text-accent mr-2" />
          <span className="text-text">
            <strong>Description:</strong> {project.description}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Modal;
