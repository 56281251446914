import { Link } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareXTwitter,
  faSquareFacebook,
  faSquareInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  return (
    <div className="flex flex-row flex-wrap text-white bg-nav px-4  pt-8 text-text-light gap-y-6  pb-4">
      <div className="hidden w-full flex-wrap items-start justify-center md:flex">
        <div className="flex w-4/12 items-center justify-center h-full">
          <img
            src="./assets/images/light version.png"
            alt="Logo"
            className="w-40"
          />
        </div>
        <div className=" flex flex-col w-4/12 items-start">
          <h2 className=" text-2xl font-bold">Quick Links</h2>
          <div className="flex flex-col  pt-8 font-semibold text-base text-gray-400 gap-y-1 items-start">
            <a href="/services">Services</a>
            <a href="/projects">Projects</a>
            <a href="/about">About us</a>
            <a href="/contact">Contact us</a>
          </div>
        </div>
        <div className="flex-col w-4/12  ">
          <h2 className="text-2xl font-bold">Contact Us</h2>
          <div className="flex-col text-wrap w-3/5 pt-8 font-semibold text-base text-gray-400 gap-y-1">
            <p>Phone: +20 122 213 1600</p>
            <p>Email: verdiEG@gmail.com</p>
            <p>
              <span>Address : </span>
              <span>41 El Batel Amed Abd El-Aziz, Mohandsin, Giza</span>
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center w-full gap-x-2">
        <a href="/">
          <FontAwesomeIcon icon={faSquareXTwitter} size="2xl" />
        </a>
        <a href="/">
          <FontAwesomeIcon icon={faSquareFacebook} size="2xl" />
        </a>
        <a href="/">
          <FontAwesomeIcon icon={faSquareInstagram} size="2xl" />
        </a>
        <a href="/">
          <FontAwesomeIcon icon={faLinkedin} size="2xl" />
        </a>
      </div>
      <div className="flex w-full items-center justify-center gap-x-2">
        <p> Copyright &copy;2024 </p>
        <Link href="/" className=" text-secondary">
          Verdi
        </Link>
      </div>
    </div>
  );
}
