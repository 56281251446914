import React from "react";
import Introduction from "./intro";
import DetailedServices from "./details";
import Achievements from "./achievments";
import Contact from "./contact";

const ServicesPage = () => {
  return (
    <div>
      <Introduction />
      <DetailedServices />
      <Achievements />
      <Contact />
    </div>
  );
};

export default ServicesPage;
