import React from "react";

const FacebookPostModal = ({ post, onClose }) => {
  if (!post) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white p-6 rounded-lg max-w-lg w-full"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="absolute top-4 right-4 text-gray-100"
          onClick={onClose}
        >
          X
        </button>
        <img
          src={post.image}
          alt={post.title}
          className="w-full h-64 object-cover rounded-lg mb-4"
        />
        <h2 className="text-2xl font-bold mb-2">{post.title}</h2>
        <p className="text-gray-600 mb-4">{post.date}</p>
        <p className="text-gray-800">{post.content}</p>
        <div className="mt-8">
          <a className="text-blue-800 font-bold" href="#">
            Facebook Link
          </a>
        </div>
      </div>
    </div>
  );
};

export default FacebookPostModal;
