import TextGenerateEffectDemo from "./textGen";
import Services from "./services";
import Projects from "./projects";
// import Newsroom from "./newsroom";
import About from "./about";

export default function Home() {
  return (
    <div className="">
      <div className="relative -mt-16 md:-mt-16">
        <video
          autoPlay
          muted
          loop
          width="1920"
          height="1050"
          preload="none"
          className="w-full lg:h-full h-[70vh] object-cover"
        >
          <source src="./assets/videos/Home.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute inset-0 bg-black bg-opacity-50 text-center flex justify-center items-center">
          <span className=" text-white text-5xl md:text-6xl lg:text-8xl font-semibold  sm:inline">
            <TextGenerateEffectDemo />
          </span>
        </div>
      </div>
      <div>
        {/* <h1 className="text-4xl lg:text-6xl font-bold text-center mt-20">
          Verdi
        </h1>
        <p className="text-xl lg:text-3xl font-light text-gray-700 text-center mt-8">
          {t("homeH1")}
        </p> */}
      </div>
      <Services />
      <Projects /> {/* Projects Section */}
      {/* <Newsroom /> */}
      <About />
      <div className="bg-background text-white py-8 flex justify-center items-center">
        <div className="flex flex-col md:flex-row justify-center items-center  w-10/12 animated-border rounded">
          <div className="bg-black w-full p-6 flex flex-col items-center justify-center">
            <h2 className="text-4xl pb-8 text-center font-semibold">
              The Company's Profile
            </h2>
            <a
              className=" bg-primary text-black px-4 py-2 rounded mt-4"
              href="./assets/Verdi_Company_Profile_V2.pdf"
            >
              Download Profile
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
