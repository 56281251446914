import React from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  Link,
  Button,
} from "@nextui-org/react";
import LanguageDropdown from "../LangDrop/LangDropDown";

export default function Nav() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  // const menuItems = ["Home", "Services", "Projects", "Newsroom", "About us"];
  const menuItems = [
    { name: "Home", link: "" },
    { name: "Services", link: "services" },
    { name: "Projects", link: "projects" },
    { name: "Newsroom", link: "newsroom" },
    { name: "Careers", link: "careers" },
    { name: "About us", link: "about" },
  ];

  return (
    <Navbar
      onMenuOpenChange={setIsMenuOpen}
      className="w-full bg-nav/80 text-text"
    >
      <NavbarContent>
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="md:hidden text-primary"
        />
        <NavbarBrand>
          <Link href="/" className="flex flex-col w-full md:inline-block">
            <img
              src="./assets/images/light version.png"
              alt="Logo"
              className="w-24 justify-center"
            />
          </Link>
        </NavbarBrand>
      </NavbarContent>
      <NavbarContent className="hidden md:flex gap-4" justify="center">
        <NavbarItem>
          <Link className="text-white" href="/">
            Home
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link className="text-white" href="/services">
            Services
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link className="text-white" href="/projects">
            Projects
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link className="text-white" href="/newsroom">
            Newsroom
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link className="text-white" href="/careers">
            Careers
          </Link>
        </NavbarItem>

        <NavbarItem>
          <Link className="text-white" href="/about">
            About us
          </Link>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent justify="end" className="hidden md:flex">
        <NavbarItem>
          <Button as={Link} className="bg-primary " href="/contact">
            Contact us
          </Button>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent
        justify="end"
        className="max-w-2 -mr-2 items-start md:flex 2xl:-mr-36 xl:-mr-36 lg:-mr-18"
      >
        <NavbarItem className="hidden">
          <LanguageDropdown />
        </NavbarItem>
      </NavbarContent>
      <NavbarMenu>
        {menuItems.map((item, index) => (
          <NavbarMenuItem
            key={`${item}-${index}`}
            data-aos="fade-right"
            data-aos-delay={`${index * 100}`} // Delay for each item
          >
            <Link
              className="w-full text-black text-xl"
              href={"/" + item.link}
              size="xl"
            >
              {item.name}
            </Link>
          </NavbarMenuItem>
        ))}
        <NavbarItem className="" data-aos="fade-right" data-aos-delay={600}>
          <Button as={Link} className="bg-primary " href="/contact">
            Contact us
          </Button>
        </NavbarItem>
      </NavbarMenu>
      ;
    </Navbar>
  );
}
